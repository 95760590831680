import { LitElement, html, css } from "lit-element";

class BlogPost extends LitElement {
  static get styles() {
    return css`
      .blog-post {
        color: var(--theme-active-text);
        background-color: var(--theme-border);
        min-height: 15vh;
        font-size: 18px;
        line-height: 1.5rem;
      }
      .blog-block {
        max-width: calc(100% - 25%);
        margin: 0 auto;
        padding: 0.5rem 0;
      }
      .blog-post-title {
        color: var(--theme-active-text);
        margin: 0;
        font-size: 28px;
        padding: 1.5rem 0 0.5rem;
      }
      .blog-post-subtitle {
        font-weight: 100;
        font-size: 22px;
      }
      .section-title {
        font-size: 22px;
        padding-top: 1rem;
        color: var(--theme-focus);
      }
      .btn-github {
        color: white;
        padding: 0.5rem 1.5rem 0.45rem;
        font-size: 12px;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        border: 2px solid var(--theme-primary-text);
        border-radius: 40px;
      }

      p,
      ul {
        max-width: 650px;
      }
      ul {
        padding-left: 1rem;
      }
      li {
        margin: 0.75rem;
      }
    `;
  }

  render() {
    return html`
      <div class="blog-post">
        <div class="blog-block">
          <h1 class="blog-post-title">Block Studio</h1>
          <div class="blog-post-subtitle">
            A Web Component Adventure in 2019
          </div>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Setup
          </div>
          <p>
            I had a hour before lunch and I wanted to catch up on the status Web
            Components. I made a simple counter. It went surprisingly well, so I
            felt the need to tug on the loose thread and see what it would
            unravel.
          </p>

          <p>
            Coming up with a good idea to explore a technology in depth was
            hard. You want something that doesn't feel like work, but at the
            same time something more interesting than a todo app. I happened to
            have framer.com open in a tab and thought, "maybe this would be
            fun".
          </p>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Challenge
          </div>
          <p>
            How would you make a Framer-like studio app with Web Components?
          </p>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Stack
          </div>
          <ul>
            <li>lit-html</li>
            <li>lit-element</li>
          </ul>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Layout
          </div>
          <p>
            How would you make a Framer-like studio app with Web Components?
          </p>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Block State
          </div>
          <p>
            How would you make a Framer-like studio app with Web Components?
          </p>
        </div>

        <div class="blog-block">
          <div class="section-title">
            The Takeaway
          </div>
          <ul>
            <li>
              It's not controversial to use Web Components. Web Components are a
              web standard.
            </li>
            <li>Properties go down. Events go up.</li>
            <li>Ship less bloat. Write less JS.</li>
            <li>
              CSS is the real hero: variables, grid, flexbox, calc().
            </li>
            <li>
              I was pleasantly surprised to learn about &lt;input type="color"
              /&gt;
            </li>
          </ul>
        </div>
      </div>
    `;
  }
}

customElements.define("blog-post", BlogPost);
